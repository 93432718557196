<template>
  <base-template
    :description="$t('userCenter.noCoupons')"
    :empty-image="emptyImage"
    :is-empty="isEmpty"
  >
    <div class="tab-box">
      <span @click="myCupones">{{ $t('userCenter.effectiveTicket') }}</span>
      <span class="is-active">{{ $t('userCenter.uneffectiveTicket') }}</span>
    </div>
    <cupones-list ref="cuponesList" class="cupones-list" :ticket-list="dataList" is-invalid />
  </base-template>
</template>

<script>
import CuponesList from '../components/cuponesList'
import { getMyTicket } from '@/api/axios/index'
export default {
  components: { CuponesList },
  data() {
    return {
      dataList: [],
      isEmpty: false,
      emptyImage: require('@/assets/images/userCenter/no-cupones-icon.png')
    }
  },
  created() {
    this.getMyTicket()
  },
  methods: {
    // 获取券列表
    async getMyTicket() {
      const { data } = await getMyTicket({ ticket_type: 2 }) || {}
      this.dataList = data || []
      this.isEmpty = !this.dataList.length
    },

    myCupones() {
      history.back(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-left:0;
  padding-right:0;
}

:deep(.el-empty__image img){
  width: 322px;
  height: 322px;
}

:deep(.empty__description){
  font-size: 32px;
  font-weight: 500;
  color: #858597;
  line-height: 38px;
}

.tab-box{
  display: flex;
  height: 96px;
  background: #fff;
  span{
    flex: 1;
    line-height: 96px;
    text-align: center;
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: rgba(0,0,0,0.4);
  }
  span.is-active{
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    position: relative;
    &::after{
      content: '';
      position: absolute;
      height: 6px;
      background: #21283DFF;
      width: 60px;
      bottom: 0;
      left: 50%;
      margin-left: -30px;
    }
  }
}
.cupones-list{
  padding: 0 40px;
  height: calc(100vh - 230px);
  overflow: auto;
  padding-top: 120px;
}
</style>
